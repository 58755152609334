import React from 'react'
import product_1 from '../Components/Asset/product_1.jpg'
import product_2 from '../Components/Asset/product_2.jpg'
import product_3 from '../Components/Asset/product_3.jpg'
import product_4 from '../Components/Asset/product_4.jpg'
function ProductCard() {    
  return (
    <>
    <section className='section mt-5'>
    <div className='container'>
      <div className='row'>
          <div className='col-md-3'>
            <div className='card'>
              <img src={product_1} className='card-img-top' alt='' />
              <div className='card-body'>
                <h5 className='card-title'>IR BLASTER Smart Wi-Fi Universal Remote (IR Blaster) for TV, AC, Music System.</h5>
                <button className='btn btn-primary'>Add To Cart</button>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card'>
              <img src={product_2} className='card-img-top' alt='' />
              <div className='card-body'>
                <h5 className='card-title'>Smart WiFi Retrofit Switch | Wi-Fi Controlled | Alexa & Google Assistant</h5>
                <a href='!' className='btn btn-primary'>Add To Cart</a>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card'>
              <img src={product_3} className='card-img-top' alt='' />
              <div className='card-body'>
                <h5 className='card-title'>Smart Node 16A | Tuya Control | Smart Life | Alexa & Google Assistant</h5>
                <a href='!' className='btn btn-primary'>Add To Cart</a>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card'>
              <img src={product_4} className='card-img-top' alt='' />
              <div className='card-body'>
                <h5 className='card-title'>Smart Wi-Fi plug compatible with google assistant & alexa, supports upto 10A and comes</h5>
                <a href='!' className='btn btn-primary'>Add To Cart</a>
              </div>
            </div>
          </div>
      </div>
    </div>
    </section>
    </>
  )
}

export default ProductCard;