import React from 'react';

function Careers() {
  return (
    <div>
      
    </div>
  );
}

export default Careers;
