import React from 'react';
import ProductCard from '../ProductCard';

function Product() {
  return (
    <div>
      <ProductCard />
    </div>
  );
}

export default Product;
