import React from "react";

function Contactus() {
    return (
        <>
            <section className="section">
                <div className="container">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>Contact Form</h6>
                                    <hr />
                                    <div className="form-group">
                                        <lable className="mb-1" >Full Name</lable>
                                        <input type="text" className="form-control" placeholder="Enter Full Name" />
                                    </div>
                                    <div className="form-group">
                                        <lable className="mb-1" >Phone Number</lable>
                                        <input type="text" className="form-control" placeholder="Enter Phone Number" />
                                    </div>
                                    <div className="form-group">
                                        <lable className="mb-1" >Email Address</lable>
                                        <input type="text" className="form-control" placeholder="Enter Email Address" />
                                    </div>
                                    <div className="form-group">
                                        <lable className="mb-2" >Message</lable>
                                        <textarea rows="3" className="form-control" placeholder="Type your Message..."></textarea>
                                    </div>
                                    <div className="form-group">
                                        <button type="button" className="btn btn-primary shadow w-100">Send Message</button>
                                    </div>
                                </div>
                                <div className="col-md-6 border-start">
                                    <h5 className="main-heading">Address Information</h5>
                                    <div className="underline"></div>
                                    <div style={{ position: 'relative', textAlign: 'right', width: '600px', height: '400px' }}>
                                        <div style={{ overflow: 'hidden', background: 'none', width: '600px', height: '400px' }}>
                                            <iframe
                                                title="Google Map"
                                                className="gmap_iframe"
                                                frameBorder="0"
                                                scrolling="no"
                                                marginHeight="0"
                                                marginWidth="0"
                                                src="https://maps.google.com/maps?width=600&height=400&hl=en&q=Hi-Fi IT Park&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                                                style={{ width: '600px', height: '400px' }}
                                            ></iframe>
                                            <a href="https://sprunkin.com/">Sprunki Incredibox</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

export default Contactus;