import React from "react";
// import Slider from "../Slider";
import { Link } from "react-router-dom";
import Card from "../Card";
import Content from "../Content";
import Banner from "../Banner";


function Home() {
    return (
        <>
            <Banner />
            {/* <Slider /> */}
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="main_heading">Our Company</h2>
                            <div className="underline mx-auto"></div>
                            <p>Welcome to Hi-Fi Automation, your premier source for advanced automation technologies. Our mission is to revolutionize industries through innovative automation solutions that enhance efficiency, productivity, and reliability.
                            </p>
                            <Link to="/about" className="btn btn-warning shadow">Read More</Link>
                        </div>
                    </div>
                </div>
            </section>


            <Card />
            <Content />
        </>

    );

}

export default Home;
