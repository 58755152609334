import React from 'react';
import Lottie from "lottie-react"

import curtain from '../Components/Asset/curtain_1.json'
import content_1 from '../Components/Asset/self_power1.jpg'
import content_2 from '../Components/Asset/self_power2.jpg'
import content_3 from '../Components/Asset/self_power3.jpg'
import content_4 from '../Components/Asset/self_power4.jpg'
import smart_1 from '../Components/Asset/smart_choice.jpg'
import smart_2 from '../Components/Asset/smart_choice2.jpg'
import icon_1 from '../Components/Asset/icon_1.jpg'
import icon_2 from '../Components/Asset/icon_2.jpg'
import icon_3 from '../Components/Asset/icon_3.jpg'
import icon_4 from '../Components/Asset/icon_4.jpg'
import icon_5 from '../Components/Asset/icon_5.jpg'
import icon_6 from '../Components/Asset/icon_6.jpg'
import gif_2 from '../Components/Asset/gif1.gif'

function Content() {
    return (
        <>

<section className='section'>
                <div className='container'>
                    <div class="row justify-content-around mt-4">
                    <img src={gif_2} alt='self_power_switch' height={600} />
                        
                    </div>
                </div>
            </section>


            <section className='section bg-light'>
                <div className='container'>
                    <div class="row justify-content-around mt-4">
                        <div class="col-4 text-center">
                            <img src={content_1} alt='self_power_switch' />
                        </div>
                        <div class="col-4 text-center pt-5">
                            <h3>Self-Powering</h3>
                            <h3>Wireless Switch !</h3>
                        </div>
                    </div>
                    <div class="row justify-content-around mt-4">
                        <div class="col-md-6 text-center">
                            <h3>Hi, how can I help?</h3>
                            <div data-aos="fade-up">
                                <p>Revolutionize your home automation with our Battery less Wi-Fi Switch - a breakthrough in convenience and sustainability. No batteries, no limits - this wireless marvel is waterproof, dust-proof, and operates devices with a simple touch. With a robust 16A capacity, 1 gang, and 2-way operation, it's your key to controlling lights, motors, and more. Effortless installation on any surface brings intelligence to your fingertips. Elevate your living space, simplify your life, and embrace a greener future. Experience innovation like never before with the Battery less Wi-Fi Switch - where convenience meets sustainability. </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <img src={content_2} alt='self_power_switch' />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div class="row justify-content-around mt-4">
                        <div class="col-md-4 text-center">
                            <img src={content_3} alt='self_power_switch' />
                        </div>
                        <div class="col-md-4 text-center pt-5">
                            <h3>Modular Touch</h3>
                            <h3>Regulation Switch</h3>
                        </div>
                    </div>
                    <div class="row justify-content-around mt-4">
                        <div class="col-md-4 text-center pt-5">
                            <p data-aos="fade-up">Dual Colour Back LED lightsCapacitive Touch Technology Ease in Installation as well as Operation Fits with any standard modular switchboard</p>
                        </div>
                        <div class="col-md-4 text-center">
                            <img src={content_4} alt='self_power_switch' />
                        </div>
                    </div>
                </div>
            </section>
            <section className='section bg-dark'>
                <div className='container'>
                    <div className='text-center my-4 text-white'>
                        <h3>Unlock the Full Potential of Your Home!</h3>
                    </div>
                    <div class="container overflow-hidden">
                        <div class="row gy-5">
                            <div class="col-6">
                                <div class="text-center" data-aos="fade-right">
                                    <img src={icon_1} className='w-20' alt='' />
                                    <p className='text-white text-center'>Memory Function</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-center" data-aos="fade-left">
                                    <img src={icon_2} className='w-20' alt='' />
                                    <p className='text-white text-center'>Voice Control</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-center" data-aos="fade-right">
                                    <img src={icon_3} className='w-20' alt='' />
                                    <p className='text-white text-center'>Speed Variation</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-center" data-aos="fade-left">
                                    <img src={icon_4} className='w-20' alt='' />
                                    <p className='text-white text-center'>Lighting Control</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-center" data-aos="fade-right">
                                    <img src={icon_5} className='w-20' alt='' />
                                    <p className='text-white text-center'>Schedule Operation</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-center" data-aos="fade-left">
                                    <img src={icon_6} className='w-20' alt='' />
                                    <p className='text-white text-center'>Human Control</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section bg-c-light'>
                <div className='container'>
                    <div class="row justify-content-around mt-4">
                        <div class="col-md-4">
                            <p className=' fw-bolder'>The smart choice!</p>
                            <ul>
                                <li>Thermostat feature</li>
                                <li>New interface design</li>
                                <li>Flat Screen</li>
                                <li>Slim and vertical design</li>
                                <li>Timer Scenarios</li>
                            </ul>
                        </div>
                        <div class="col-md-4 text-center">
                            <img src={smart_1} className='' alt='' />
                        </div>
                    </div>
                    <div class="row justify-content-center mt-4">
                        <div class="col-md-4 text-center">
                            <img src={smart_2} className='' alt='' />
                        </div>
                        <div class="col-md-6 pt-5">
                            <ul>
                                <li>Smart Air Conditioner Controller: Your Air Conditioning Partner. You can say, "Alexa, turn on/off Air Conditioning, turn up/down temperature, set temperature to value etc.</li>
                                <li>Smartphone app Control : Home Mate Smart IR can replace most of the traditional remote controls in your home.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section'>
                <div className='container'>
                    <div class="row justify-content-around mt-4">
                        <div class="col-md-4">
                            <p className=' fw-bolder'>Live in Comfort</p>
                            <ul>
                                <li>Smart curtains can be used in tandem with a dedicated app.</li>
                                <li>Some curtains even let you use voice commands, thereby ensuring you can open or close the blinds from anywhere. And, if you have already splurged on beautiful fabric curtains, then fret not.</li>
                                <li>This guide has a smart hack for you as well!</li>
                            </ul>
                        </div>
                        <div class="col-md-4 text-center">
                           <div class="text-center">
                            <Lottie loop animationData={curtain} />
                           </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Content;




