import React from 'react'
import card_1 from "../Components/Asset/home_automation.jpg"
import card_2 from "../Components/Asset/repair.jpg"
import card_3 from "../Components/Asset/smart_home.jpg"
import { Link } from 'react-router-dom';

function Card() {
    return (
        <section className="section border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4 text-center">
                            <h1 className="main_heading">Our Service</h1>
                            <div className="underline mx-auto"></div>
                        </div>

                        <div className="col-md-4">
                            <div className="card shadow">
                                <img src={card_1} className="w-100 border-bottom" alt="service" />
                                <div className="card-body">
                                    <h4>Home Automation Installation</h4>
                                    <div className="underline"></div>
                                    <p> Many smart home service providers offer installation service for various smart devices such as thermostats, lighting</p>
                                    <div className='text-center'>
                                    <Link to='/contact'>
                                    <button type="button" class="btn btn-info text-uppercase text-white">installation quotes</button>
                                    </Link>
                                    {/* <Link to='/service' className="btn btn-link btn-info text-uppercasek">Read More...</Link> */}
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow">
                                <img src={card_2} className="w-100 border-bottom" alt="service" />
                                <div className="card-body">
                                    <h4>Maintenace and Repair</h4>
                                    <div className="underline"></div>
                                    <p>Smart home consultants can provide advice and recommendation on how to optimize your homes automation
                                    </p>
                                    <div className='text-center'>
                                    <Link to='/product'>
                                    <button type="button" class="btn btn-info text-uppercase text-white">view products</button>
                                    </Link>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow">
                                <img src={card_3} className="w-100 border-bottom" alt="service" />
                                <div className="card-body">
                                    <h4>Smart Home consultation</h4>
                                    <div className="underline"></div>
                                    <p>Smart home service providers can also offer maintenance and repair services to ensure that your smart device are</p>
                                    <div className='text-center'>
                                    <Link to='/contact'>
                                    <button type="button" class="btn btn-info text-uppercase text-white">Book Services</button>
                                    </Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Card