import React from "react";
import Lottie from "lottie-react"

import about from '../Asset/About.json'

function About() {
    return(
        <div>
           <section className="section border-bottom bg-dark">
                <div className="container text-white">
                    <h2 className="text-center">About Us</h2>
                    <div class="text-center">
                            <Lottie loop animationData={about}/>
                           </div>
                    <h4 className="text-center">We help people find their dream jobs and companies find the best talent.</h4>
                </div>
           </section>

           <section>
                    <div className="p-4">
                        <p className="text-center">Welcome to Hi-Fi Automation, your premier source for advanced automation technologies. Our mission is to revolutionize industries through innovative automation solutions that enhance efficiency, productivity, and reliability.</p>
                        <p className="text-center">From state-of-the-art robotics to intelligent software systems, Hi-Fi Automation is at the forefront of transforming business operations across sectors.</p>
                    </div>
                    <hr />
                    <div className="pb-4">
                        <h2 className="text-center pb-4 text-danger">Our Story</h2>
                        <p className="text-center">Founded in 2023, Hi-Fi Automation emerged from a vision to lead the industry in automation technology. We recognized the increasing need for intelligent solutions that streamline processes and drive growth.</p>
                        <p className="text-center"> Today, we are proud to be a trusted partner for businesses looking to embrace the future of automation.</p>
                    </div>
                        <hr />
                    <div className="pb-4">
                        <h2 className="text-center pb-4 text-danger">Our Solutions</h2>
                        <h6 className="text-center pb-5">We offer a comprehensive range of automation solutions tailored to meet diverse industry needs:</h6>
                        <p className="text-center"><b>Industrial Automation :</b> Enhance manufacturing processes with our cutting-edge robotic systems and automation software.</p>
                        <p className="text-center"><b>Home Automation :</b> Experience convenience and security with our smart home technologies, including automated lighting, climate control, and security systems.</p>
                        <p className="text-center"><b>Software Automation :</b> Optimize business operations with our intelligent software solutions, from process automation to AI-driven analytics.</p>
                        <p className="text-center"><b>AI and Machine Learning :</b> Leverage the power of AI to make data-driven decisions and improve operational efficiency.</p>
                    </div>
                    <hr />
                    <div className="pb-4">
                        <h2 className="text-center pb-4 text-danger">Our Commitment</h2>
                        <h6 className="text-center pb-5">At Hi-Fi Automation, we are dedicated to:</h6>
                        <p className="text-center"><b>Innovation :</b> Continuously advancing our technologies to provide cutting-edge automation solutions.</p>
                        <p className="text-center"><b>Quality :</b> Ensuring our products and services meet the highest standards of excellence and reliability.</p>
                        <p className="text-center"><b>Sustainability :</b> Committing to eco-friendly practices and sustainable solutions in automation.</p>
                        <p className="text-center"><b>Customer Satisfaction :</b> Prioritizing customer needs and providing exceptional support and service.</p>
                    </div>
                    <hr />
                    <div className="pb-4">
                        <h2 className="text-center pb-4 text-danger">Why Choose Us?</h2>
                        <p className="text-center"><b>Comprehensive Solutions :</b> From hardware to software, we offer a full suite of automation products and services.</p>
                        <p className="text-center"><b>Expertise :</b> Our team of experts brings unparalleled knowledge and experience in automation technologies.</p>
                        <p className="text-center"><b>Customer-Centric Approach :</b> We work closely with our clients to tailor solutions that best meet their needs and goals.</p>
                        <p className="text-center"><b>Future-Ready :</b> We invest in the latest technologies to ensure our solutions are future-proof and scalable.</p>
                    </div>
                    <hr />
                    <div className="pb-4">
                        <h2 className="text-center pb-4 text-danger">Join Our Community</h2>
                        <p className="text-center">Join Hi-Fi Automation's community to stay updated on our latest innovations, industry trends, and special offers. Follow us on [Social Media Links] and subscribe to our newsletter for exclusive content.</p>
                        <p className="text-center">Thank you for choosing Hi-Fi Automation as your partner in the automation journey. We look forward to empowering your success with our state-of-the-art solutions!</p>
                    </div>
           </section>
        </div>
    );

}

export default About;