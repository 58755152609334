import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <section className='section footer bg-dark text-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <h4>Company Information</h4>
                            <hr />
                            <div><p className='text-white mb-1'>Privacy policy</p></div>
                            <div><p className='text-white mb-1'>Terms & conditions</p></div>
                            <div className='footer-icon'>
                                <a href="https://www.facebook.com/profile.php?id=61562480334115&mibextid=ZbWKwL" target='blank'>
                                 <i class="fa-brands fa-facebook px-2"></i>
                                </a>
                                <a href="https://www.instagram.com/hifi_itpark_hrms?igsh=MWQ1OThwNnlwYng2cw==" target='blank'>
                                <i class="fa-brands fa-instagram px-2"></i>
                                </a>
                                <a href="https://www.youtube.com/@HIfI_ITPARK_HRMS" target='blank'>
                                <i class="fa-brands fa-youtube px-2"></i>
                                </a>
                                </div>
                        </div>
                        <div className='col-md-4'>
                            <h4>Quick Links</h4>
                            <hr />
                            <div><Link to='/'>Home</Link></div>
                            <div><Link to='https://fmcg.hifiitpark.com/index.html'>Product</Link></div>
                            <div><Link to='https://hrms.hifiitpark.com/html/Careers.html'>Careers</Link></div>
                            <div><Link to='/about'>About</Link></div>
                            <div><Link to='/contact'>Contact</Link></div>
                        </div>
                        <div className='col-md-4'>
                            <h4>Contact Information</h4>
                            <hr />
                            <div><a href="mailto:support@hifiitpark.com">support@hifiitpark.com</a></div>
                            <div><a href="https://wa.me/+918754309295" target='blank'>87543 09295</a></div>
                            <div><Link to='/contact'>34 / 15, Balavinayakar Kovil Street, <br />Tuticorin - 628002</Link></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer
