// Developed by 'Dhinesh Kumar'

import React from 'react';
import './App.css'
import Contact from './Components/Pages/Contact';
import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
// import Header from './Components/Header';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './Components/Footer';
import Product from './Components/Pages/Product';
import Careers from './Components/Pages/Careers';


function App() {
  return (
   <div>
    <Router>
      {/* <Header /> */}
      <Navbar />
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
   </div>
  );
}

export default App;
