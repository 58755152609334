import React from 'react'
import logo from '../Components/Asset/logo.png'
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <div className="navbar-dark sticky-top bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <nav class="navbar navbar-expand-lg">
                            <div class="container-fluid">
                                <Link to="/" class="navbar-brand">
                                <img src={logo} alt="logo" width="250" height="43" />
                                </Link>
                                
                                
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-uppercase">
                                        <li class="nav-item">
                                            <Link to="/" class="nav-link active">Home</Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="https://fmcg.hifiitpark.com/index.html" class="nav-link active">Product</Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="https://hrms.hifiitpark.com/html/Careers.html" class="nav-link active">Careers</Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/about" class="nav-link active">About Us</Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/contact" class="nav-link active">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar