import React from 'react'
import wave from "../Components/Asset/wave1.png"
import gif from "../Components/Asset/gif4.gif"

function Banner() {
  return (
    <>
      <section id="banner">
                <div class="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="promo-title">Intelligent <span>Living</span> at <br /> Your Fingertips</h1>
                            <p className="text-white">Transform Your Space with Advanced Home Automation</p>
                        </div>
                        <div className="col-md-6 text-center">
                        <img src={gif} alt='automation_gif' width={600}/>
                        </div>
                    </div>
                </div>
             
                    <img src={wave} className="bottom-img" alt="wave-pattern" />
      
            </section>
    </>
  )
}

export default Banner
